$spacing: (
  '0': 0,
  '1': $spacing-01,
  '2': $spacing-02,
  '3': $spacing-03,
  '4': $spacing-04,
  '5': $spacing-05,
  '6': $spacing-06,
  '7': $spacing-07,
  '8': $spacing-08,
  '9': $spacing-09,
  '10': $spacing-10,
  '11': $spacing-11,
  '12': $spacing-12,
  '13': $spacing-13,
);

@each $name, $size in $spacing {
  .p-#{$name} {
    padding: $size !important;
  }
}

@each $name, $size in $spacing {
  .pt-#{$name} {
    padding-top: $size !important;
  }
}

@each $name, $size in $spacing {
  .pb-#{$name} {
    padding-bottom: $size !important;
  }
}

@each $name, $size in $spacing {
  .pr-#{$name} {
    padding-right: $size !important;
  }
}

@each $name, $size in $spacing {
  .pl-#{$name} {
    padding-left: $size !important;
  }
}

@each $name, $size in $spacing {
  .px-#{$name} {
    padding-left: $size !important;
    padding-right: $size !important;
  }
}

@each $name, $size in $spacing {
  .py-#{$name} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
}

@each $name, $size in $spacing {
  .m-#{$name} {
    margin: $size !important;
  }
}

@each $name, $size in $spacing {
  .mt-#{$name} {
    margin-top: $size !important;
  }
}

@each $name, $size in $spacing {
  .mb-#{$name} {
    margin-bottom: $size !important;
  }
}

@each $name, $size in $spacing {
  .mr-#{$name} {
    margin-right: $size !important;
  }
}

@each $name, $size in $spacing {
  .ml-#{$name} {
    margin-left: $size !important;
  }
}

@each $name, $size in $spacing {
  .mx-#{$name} {
    margin-left: $size !important;
    margin-right: $size !important;
  }
}

@each $name, $size in $spacing {
  .my-#{$name} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }
}

.m {
  &-auto {
    margin: 0 auto !important;
  }
}

@each $name, $size in $spacing {
  @each $breakpoint in map-keys($carbon--grid-breakpoints) {
    $infix: carbon--breakpoint-infix($breakpoint);

    @include carbon--breakpoint($breakpoint, $carbon--grid-breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .m#{$infix}-#{$name} {
        margin: $size !important;
      }

      .mr#{$infix}-#{$name} {
        margin-right: $size !important;
      }

      .ml#{$infix}-#{$name} {
        margin-left: $size !important;
      }

      .mt#{$infix}-#{$name} {
        margin-top: $size !important;
      }

      .mb#{$infix}-#{$name} {
        margin-bottom: $size !important;
      }

      .my#{$infix}-#{$name} {
        margin-top: $size !important;
        margin-bottom: $size !important;
      }

      .mx#{$infix}-#{$name} {
        margin-left: $size !important;
        margin-right: $size !important;
      }
    }
  }
}
