.debug-box {
  border: solid 1px blue;
  padding: 10px;
  background-color: #eee;
}

@import 'styles/settings/settings.scss';
@import 'styles/vendors/vendors.scss';

body.ibm {
  @import 'styles/elements/elements.scss';
  @import 'styles/common/common.scss';
  @import 'styles/components/components.scss';
}
