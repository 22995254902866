ibm-table {
  img.molecule-img {
    border-radius: 5px;
    padding: 3px;
    background-color: white;
  }
  tr:hover {
    img.molecule-img {
      box-shadow: 2px 3px 10px 2px rgb(0 0 0 / 8%);
    }
  }
  td {
    &.break-all-cell {
      word-break: break-all;
    }
  }
}
