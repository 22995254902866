@import 'src/styles/settings/settings.scss';

.ibm--retro {
  &-wizard {
    h4 {
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 600;
      color: $text-01;
    }
  }
  &-outcomes-sidebar {
    .bx--structured-list {
      min-width: auto;
      &.bx--structured-list--border {
        border: none;
        background-color: transparent;
      }
      .bx--structured-list-td {
        padding: 0 0.5rem;
        vertical-align: middle;
      }
    }
    &-sequence-td {
      height: 50px;
      &-print-pdf {
        padding: 0 !important;
      }
      &-checkbox {
        width: 16px;
      }
      &-chart {
        padding: 0 !important;
        svg {
          margin: 0;
          padding: 0;
        }
      }
      &-messages {
        display: inline-block;
        vertical-align: middle;
        padding-top: 0.2rem;
        svg {
          margin-right: 0.25rem;
        }
        &-info {
          color: $interactive-01;
        }
        &-warning {
          color: $support-03;
        }
        &-error {
          color: $danger-02;
        }
      }
    }
    &-parameters-td {
      height: 34px;
    }
  }
  &-feedback {
    &-header {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      color: $text-01;
    }
    &-description {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500;
      color: $text-01;
    }
  }
}
