$ibm-avatar__xs: 24px;
$ibm-avatar__sm: 30px;
$ibm-avatar__md: 50px;
$ibm-avatar__xl: 174px;

$ibm-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
$ibm-modal-background: rgba(68, 84, 93, 0.8);

$ibm-badge-notification: 19px;
$ibm-nav-height: 48px;
$ibm-username-characters: 130px;
$ibm-fixed-header: 55px;
$ibm-fixed-footer: 44px;
$ibm-card-footer: 65px;
$ibm-card-footer-spacing: 80px;
$ibm-menu-spacing: 16px 0;
$ibm-btn-sm: 15px;
$ibm-workspace-sidebar: 60px;
