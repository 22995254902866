@import 'src/styles/settings/settings.scss';
@import 'src/styles/settings/_breakpoints.scss';

ibm-sidenav {
  overflow: visible;
  .bx--side-nav__items {
    padding-top: 0;
  }

  .bx--side-nav__navigation {
    @media (max-width: breakpoint('lg')) {
      box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .bx--side-nav__link,
  .bx--side-nav__submenu {
    padding: 0.8rem;
    height: auto;
  }

  .external-link {
    .bx--side-nav__link-text {
      width: 100%;
    }
  }

  .svg-icon {
    height: 16px;
  }

  .sidenav-divider {
    border-bottom: solid 1px $text-02;
    margin: 1rem;
  }
  .bx--side-nav__icon_hidden {
    .bx--side-nav__icon {
      display: none;
    }
  }
}
