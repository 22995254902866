.bx--header__nav {
  display: block;
}

ibm-table {
  .bx--expandable-row:not(.bx--parent-row) {
    td {
      background: $white !important;
    }
  }

  .bx--data-table td.bx--table-expand[data-previous-value='collapsed'] {
    border-bottom: 1px solid $button-separator;
  }
  .favorite-header-cell {
    .bx--table-sort {
      background-image: url('~src/assets/img/icons/star.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 1rem 1rem;

      &.bx--table-sort--active,
      &:hover {
        background-image: url('~src/assets/img/icons/star--filled.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 1rem 1rem;
        background-color: $hover-selected-ui;
      }
    }

    .bx--table-sort__flex {
      display: none;
    }
  }

  .thead_action.favorite-header-cell-filled {
    background-image: url('~src/assets/img/icons/star--filled.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
  }

  .bx--table-column-checkbox .bx--checkbox-label {
    padding-left: $spacing-07;
  }
}

ibm-table-toolbar {
  .bx--table-toolbar {
    background: $ui-background;
  }
}

ibm-structured-list {
  .bx--structured-list {
    margin-bottom: 0;
  }
}

.bx--text-input__field-wrapper {
  .bx--text-input__invalid-icon {
    top: 50% !important;
  }
}

ibm-toast {
  &.bx--toast-notification {
    margin: 0;
    &:first-child {
      margin: 0;
    }
  }
}
