html,
body {
  height: 100%;
  font-family: $base-font;
}

hr {
  border: 0;
  border-top: solid 1px $ui-02;
  background: transparent;
}

.information-overflow-menu {
  width: auto !important;
  z-index: 5999;
  min-width: 250px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  &:after {
    content: none;
  }

  &__border {
    border-top: 1px solid $ui-03;
  }
}

#teconsent {
  display: none !important;
}
