a,
.cursor-pointer {
  cursor: pointer;
}
.ml-auto {
  margin-left: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.align {
  display: flex;
  align-items: center;
  &-right {
    justify-content: flex-end;
  }
  &-left {
    justify-content: flex-start;
  }
  &-center {
    align-items: center;
    justify-content: space-between;
  }
}

.v-align {
  &-middle {
    vertical-align: middle;
  }
}

.float {
  &-right {
    float: right;
  }
}

.flex {
  &-grow-none {
    flex-grow: unset;
  }
  &-shrink-1 {
    flex-shrink: 1;
  }
}

.gap {
  &-1 {
    gap: 0.5rem;
  }
  &-2 {
    gap: 1rem;
  }
  &-3 {
    gap: 1.5rem;
  }
  &-4 {
    gap: 2rem;
  }
}

.muted {
  color: $ui-04 !important;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.font-weight {
  &-light {
    font-weight: 300;
  }
  &-normal {
    font-weight: 400;
  }
  &-semibold {
    font-weight: 600;
  }
}

.font {
  &-caption-01 {
    @include carbon--type-style('caption-01');
  }
  &-caption-02 {
    @include carbon--type-style('caption-02');
  }
  &-productive-heading-03 {
    @include carbon--type-style('productive-heading-03');
  }
}

.small {
  &--xs {
    font-size: 0.75rem;
  }
  &--sm {
    font-size: 0.875rem;
  }
  &--md {
    font-size: 1rem;
  }
}

.break-all {
  word-break: break-all;
}

.w {
  &-50 {
    width: 50%;
  }
  &-100 {
    width: 100%;
  }
  &-spacing-5 {
    width: 5rem;
  }
  &-spacing-10 {
    width: 10rem;
  }
  &-spacing-12 {
    width: 12rem;
  }
  &-spacing-15 {
    width: 15rem;
  }
  &-spacing-20 {
    width: 20rem;
  }
}
.max-width-none {
  max-width: none !important;
}

.h-100 {
  height: 100%;
}

.vh {
  &-100 {
    height: 100vh;
  }

  &-50 {
    height: 50vh;
  }
}

.max {
  &-w-100 {
    max-width: 100%;
  }
  &-w-inherit {
    max-width: inherit;
  }
}

.clear-both {
  clear: both;
}

.hidden {
  display: none;
}

.text {
  &-01 {
    color: $text-01;
  }
  &-04 {
    color: $text-04;
  }
  &-danger {
    color: $danger-02;
  }
  &-interactive-01 {
    color: $interactive-01;
  }
  &-support-03 {
    color: $support-03;
  }
  &-placeholder {
    color: $text-placeholder;
  }
  &-secondary {
    color: $text-secondary;
  }

  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-decoration-none {
    text-decoration: none;
  }
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-capitalize {
    text-transform: capitalize;
  }
}

.fill {
  &-text-01 {
    fill: $text-01;
  }
  &-danger {
    fill: $danger-02;
  }
  &-interactive {
    fill: $interactive-01;
  }
  &-support-03 {
    fill: $support-03;
  }
  &-white {
    fill: white;
  }
  &-success {
    path {
      fill: $support-success !important;
    }
  }
}

.bg {
  &-danger {
    background-color: $danger-01;
  }
  &-danger-light {
    background: rgba($danger-01, 0.2);
  }
  &-white {
    background-color: $ui-01;
  }
  &-default {
    background-color: $ui-background;
  }
  &-ui-03 {
    background-color: $ui-03;
  }
  &-ui-04 {
    background-color: $ui-04;
  }
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-basis {
  &-0 {
    flex-basis: 0;
  }
  &-25 {
    flex-basis: 25%;
  }
  &-33 {
    flex-basis: 33.33%;
  }
  &-50 {
    flex-basis: 50%;
  }
  &-100 {
    flex-basis: 100%;
  }
}

.align-items-center {
  align-items: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-between {
  justify-content: space-between;
}

.flex-grow {
  &-1 {
    flex-grow: 1;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.align-items-center {
  align-items: center;
}

.font-weight-bold {
  font-weight: bold;
}

.justify-content-between {
  justify-content: space-between;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-sticky {
  position: sticky;
}

.header-separator {
  height: 34px;
  width: 1px;
  background-color: $ui-03;
  display: inline-block;
}

.h-divider {
  border-bottom: 1px solid $ui-04;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.border {
  border: 1px solid $ui-04;

  &-bottom {
    border-bottom: 1px solid $ui-04;
  }

  &-top {
    border-top: 1px solid $ui-04;
  }

  &-right {
    border-right: 1px solid $ui-04;
  }
  &-left {
    border-left: 1px solid $ui-04;
  }

  &-top-light {
    border-top: 1px solid $ui-03;
  }

  &-light {
    border: 1px solid $ui-03;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.min-h-0 {
  min-height: 0;
}

.min-h-100 {
  min-height: 100%;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.invisible {
  visibility: hidden;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-pre-line {
  white-space: pre-line !important;
}

.no-link a {
  pointer-events: none;
}

ibm-label.highlight-input,
ibm-number.highlight-input {
  input {
    border: 1px solid $border-interactive;
  }
  .bx--number__controls {
    border-right: 1px solid $border-interactive;
  }
  .bx--number__control-btn {
    border: 0;
    &:hover {
      border-top: 1px solid $border-interactive;
      border-bottom: 1px solid $border-interactive;
    }
  }
}
ibm-select.highlight-input {
  select {
    border: 1px solid $border-interactive;
  }
}

.debug {
  border: solid 1px red;
}

.d-none {
  display: none;
}

.text-decoration {
  &-underline {
    text-decoration: underline;
  }
}

.object-fit-contain {
  object-fit: contain;
}
