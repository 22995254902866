.termsfeed-com---nb .cc-nb-title {
  color: #0353e9 !important;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background: #0353e9 !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  background: #0353e9 !important;
}

.termsfeed-com---palette-light .cc-cp-foot-save {
  background: #0353e9;
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox:checked + label:before {
  background: #0353e9;
}

.termsfeed-com---palette-light .cc-cp-foot-byline {
  visibility: hidden;
}

#title_more_information {
  visibility: hidden;
}
