.ibm--welcome-sidebar {
  min-height: 100vh;
  background: $black;
  background: $inverse-link;
  background: -moz-linear-gradient(top, $inverse-link 0%, $interactive-01 100%);
  background: -webkit-linear-gradient(top, $inverse-link 0%, $interactive-01 100%);
  background: linear-gradient(to bottom, $inverse-link 0%, $interactive-01 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &--title {
    color: $white;
    font-size: 5rem;
    padding: 1rem;
    text-align: center;
    z-index: 999;
    a {
      color: $white;
      text-decoration: none;
    }
  }
  &--subtitle {
    font-size: 2rem;
    display: block;
  }
  &__logo {
    min-height: 100px;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100vh;
    background: url('~/src/assets/img/login/login-pattern.png') no-repeat bottom left;
    background-size: cover;
    position: absolute;
    display: inline-block;
    left: 0;
  }
}

.ibm--loginForm {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $white;
  &--terms {
    padding: 5px;
    position: absolute;
    right: 3vw;
    bottom: 1vh;
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    a {
      padding: 1rem 0.5rem;
      display: inline-block;
      color: $black;
      text-decoration: none;
      &:hover {
        color: $interactive-01;
      }
    }
  }
  &--wrap {
    text-align: center;
  }
  &--logo {
    display: block;
    margin: 0 auto;
    max-height: 300px;
    margin-top: 30%;
  }
  &--privacy {
    font-size: 0.875rem;
    padding: 2rem 4rem;
    text-align: left;
    max-width: 700px;
    color: $ui-05;
  }
  &__sign-up,
  &__sign-in,
  &__password-recovery,
  &__change-password {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 1rem;
  }
}

.or-separator {
  display: block;
  color: $ui-05;
  position: relative;
  z-index: 1;
  text-align: center;
  span {
    background: $white;
  }
  &:before {
    border-top: 1px solid $ui-03;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    z-index: -1;
  }
  &-100 {
    &:before {
      width: 100%;
    }
  }
}
