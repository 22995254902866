ibm-overflow-menu-pane {
  .project-header-actions-menu {
    width: 11rem;
  }

  .projects-list-actions-menu {
    width: 11rem;
  }

  .reactions-settings-material-menu {
    width: auto;
    z-index: 10000;

    .bx--overflow-menu-options__btn {
      max-width: none;
    }
  }
}

ibm-overflow-custom-menu-pane {
  .add-new-button__menu-container {
    &.bx--overflow-menu-options {
      width: auto;

      .bx--overflow-menu-options__btn {
        max-width: none;
      }
    }
  }
}

.overflow-menu {
  &:after {
    content: none;
  }

  &__border {
    border-top: 1px solid $ui-03;
  }
}
