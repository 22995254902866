ibm-modal.full-screen {
  .bx--modal-container {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }
  }
}
