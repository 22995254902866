@import 'src/styles/settings/settings.scss';

button {
  .icon-fill {
    &-danger {
      path {
        fill: $danger-02 !important;
      }
    }
    &-interactive {
      path {
        fill: $interactive-01 !important;
      }
    }
  }
}
