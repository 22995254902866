.avatar {
  display: inline-block;
  background-color: $inverse-support-04;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &--xs {
    height: $ibm-avatar__xs;
    width: $ibm-avatar__xs;
  }
  &--sm {
    height: $ibm-avatar__sm;
    width: $ibm-avatar__sm;
  }
  &--md {
    height: $ibm-avatar__md;
    width: $ibm-avatar__md;
  }
  &--xl {
    height: $ibm-avatar__xl;
    width: $ibm-avatar__xl;
  }
}
